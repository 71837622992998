import React from "react"
import { Link } from "gatsby"
import {
  FaInstagram,
  FaFacebookSquare,
  FaTshirt,
  FaShare,
} from "react-icons/fa"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

import "./layout.css"

class Layout extends React.Component {
  render() {
    const { location, title, children, avatar, shirt } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      // home page
      header = (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: rhythm(3 / 4),
          }}
        >
          <h1
            style={{
              margin: 0,
              fontFamily: `Indie Flower, sans-serif`,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <div className="header-title">{title}</div>
            </Link>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "2rem",
            }}
          >
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <a
                href="https://porum.redbubble.com"
                className="icon-link"
                title="Red Bubble t-shirt shop"
              >
                <FaTshirt />
              </a>
            </div>
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <a
                href="https://www.facebook.com/orumcartoons"
                className="icon-link"
              >
                <FaFacebookSquare />
              </a>
            </div>
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <a
                href="https://www.instagram.com/orumcartoons"
                className="icon-link"
              >
                <FaInstagram />
              </a>
            </div>
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <button
                type="button"
                style={{ background: "transparent", padding: 0, border: 0 }}
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: "Orum Cartoons",
                        text: "Check out Orum Cartoons",
                        url: "https://www.orumcartoons.com",
                      })
                      .then(() => {})
                      .catch(error => console.log("Error sharing", error))
                  }
                }}
              >
                <FaShare color="rgb(0, 122, 204)" />
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      // post page
      header = (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontFamily: `Indie Flower, sans-serif`,
              margin: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <Image
                    fixed={avatar.childImageSharp.fixed}
                    alt={title}
                    style={{
                      marginRight: rhythm(1 / 4),
                      marginBottom: 0,
                      minWidth: 50,
                    }}
                    imgStyle={{}}
                  />
                </div>
                <div className="header-title">{title}</div>
              </div>
            </Link>
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "2rem",
            }}
          >
            {!!shirt && (
              <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
                <a
                  href={shirt}
                  className="icon-link"
                  title="This cartoon in the RedBubble t-shirt shop"
                >
                  <FaTshirt color="#f4ac00" />
                </a>
              </div>
            )}
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <a
                href="https://www.facebook.com/orumcartoons"
                className="icon-link"
              >
                <FaFacebookSquare />
              </a>
            </div>
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <a
                href="https://www.instagram.com/orumcartoons"
                className="icon-link"
              >
                <FaInstagram />
              </a>
            </div>
            <div style={{ marginLeft: rhythm(1 / 3) }} className="icon-link">
              <button
                type="button"
                style={{ background: "transparent", padding: 0, border: 0 }}
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: "Orum Cartoons",
                        text: "Check out Orum Cartoons",
                        url: location.href,
                      })
                      .then(() => {})
                      .catch(error => console.log("Error sharing", error))
                  }
                }}
              >
                <FaShare color="rgb(0, 122, 204)" />
              </button>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer style={{ marginTop: rhythm(1.5), fontSize: "75%" }}>
          © 2019-{new Date().getFullYear()} Peter Orum
        </footer>
      </div>
    )
  }
}

export default Layout
